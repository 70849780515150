import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular/standalone';

@Injectable({ providedIn: 'root' })
export class ToastService {
  public static readonly DEFAULT_DURATION = 6000;
  private offlineToast: HTMLIonToastElement;

  constructor(
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) { }

  public getToast(): HTMLIonToastElement | undefined {
    return this.offlineToast;
  }

  public async show(translationKey: string) {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant(translationKey),
      duration: ToastService.DEFAULT_DURATION,
      buttons: [
        {
          text: this.translate.instant('errors.close'),
          role: 'cancel',
        },
      ],
    });

    toast.present();
  }

  public async showOffline() {
    const lang = this.translate.getBrowserLang();
    this.offlineToast = await this.toastCtrl.create({
      message:
        lang == 'nl'
          ? 'De app is momenteel offline'
          : 'The app is currently offline',
      duration: 60000,
      cssClass: 'toast-success',
      buttons: [
        {
          text: 'X',
          role: 'cancel',
        },
      ],
    });

    this.offlineToast.present();
  }

  public hideOffline() {
    if (this.offlineToast) {
      this.offlineToast.dismiss();
      this.offlineToast = undefined;
    }
  }

  public async showRetry(translationKey: string, callback: () => any) {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant(translationKey),
      duration: ToastService.DEFAULT_DURATION,
      buttons: [
        {
          text: this.translate.instant('errors.retry'),
          role: 'cancel',
          handler: () => {
            callback();
          },
        },
      ],
    });

    toast.present();
  }
}
