import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, from, of } from 'rxjs';
import {
  catchError,
  retryWhen,
  scan,
  delay,
  switchMap,
  filter,
} from 'rxjs/operators';
import { Network } from '@capacitor/network';
import { ToastService } from '../services/toast.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { NetworkService } from '../services/network.service';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  private splasScreenActive: boolean = true;

  constructor(private toastService: ToastService, private networkService: NetworkService) {
    this.networkService.online.subscribe((isOnline) => {
      if (isOnline) {
        this.toastService.hideOffline();
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
      retryWhen((errors) =>
        errors.pipe(
          scan((retryCount, error) => {
            if (this.splasScreenActive) {
              SplashScreen.hide();
              this.splasScreenActive = false;
            }
            if (
              error instanceof HttpErrorResponse &&
              error.status === 0 &&
              error.message.endsWith('0 Unknown Error')
            ) {
              if (retryCount === 3) {
                if (this.toastService.getToast() === undefined) {
                  this.toastService.showOffline();
                }
              }
              return retryCount + 1;
            } else {
              throw error;
            }
          }, 0),
          switchMap((retryCount) =>
            from(Network.getStatus()).pipe(
              filter((status) => !status.connected),
              switchMap(() => {
                console.log(
                  'No internet connection detected. Retrying request...'
                );
                return of(retryCount);
              })
            )
          ),
          delay(2000)
        )
      )
    );
  }
}
