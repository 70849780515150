import { NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Platform } from '@ionic/angular/common';
import { TranslateModule } from '@ngx-translate/core';
import mapboxgl, { MapMouseEvent } from 'mapbox-gl';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, TranslateModule, NgIf],
})
export class MapComponent implements OnInit {
  loaded: boolean = false;
  map: mapboxgl.Map;
  error: boolean = false;
  @ViewChild('map') mapRef: ElementRef<any>;
  @Output() mapLoaded: EventEmitter<mapboxgl.Map> =
    new EventEmitter<mapboxgl.Map>();
  @Output() mapClick: EventEmitter<MapMouseEvent> =
    new EventEmitter<MapMouseEvent>();

  @Input() zoom: number = 12;
  @Input() minZoom: number;
  @Input() maxZoom: number;
  @Input() center: mapboxgl.LngLat;
  @Input() padding: {};

  constructor(
    private platform: Platform,
    private deviceService: DeviceService
  ) {
    this.center = this.deviceService.defaultLocation;
  }

  ngOnInit() {
    this.error = !mapboxgl.supported;
  }

  ngAfterViewInit() {
    if (!this.error) {
      this.map = new mapboxgl.Map({
        container: this.mapRef.nativeElement,
        style: 'mapbox://styles/mapbox/streets-v12',
        projection: 'globe',
        zoom: this.zoom,
        center: this.center,
        attributionControl: false,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
      });

      this.map.addControl(
        new mapboxgl.AttributionControl({ compact: true }),
        'bottom-left'
      );
      this.map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: this.platform.is('desktop'),
        }),
        'bottom-right'
      );

      this.map.on('load', (event) => {
        this.map.resize();
        if (this.padding) this.map.setPadding(this.padding);
        this.mapLoaded.next(event.target);
        setTimeout(() => {
          this.loaded = true;
        }, 500);
      });

      this.map.on('click', (event) => {
        this.mapClick.next(event);
      });
    }
  }
}
